import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ShowTopPage from "./components/ShowTopPage";
import "./css/animate.css";
import "./css/bootstrap.css";
import "./css/responsive.css";
import "./css/style.css";
import About from "./views/About";
import Contact from "./views/Contact";
import FAQs from "./views/FAQs";
import Home from "./views/Home";
import Service from "./views/Service";
import Team from "./views/Team";

function App() {
  const [title, setTitle] = useState("Home");
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <ShowTopPage />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Package-pricing" element={<FAQs />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/about/:name" element={<About />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/team/:name" element={<Team />} />

      </Routes>
    </>
  );
}

export default App;


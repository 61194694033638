import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import MiniNavbar from "../components/MiniNavbar";
import MiniScreen from "../components/MiniScreen";
import ScrollToTop from "react-scroll-to-top";
import TeamBody from '../components/TeamBody'
const Team = () => {
  const { name } = useParams();
  useEffect(() => {
    document.title = name;
  }, [name]);
  return (
    <div className="page-wrapper">
      <ScrollToTop smooth top={300} />
      <MiniNavbar />
      <MiniScreen name={name} />
      <TeamBody/>
      <Footer />
    </div>
  );
};

export default Team;
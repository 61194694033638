import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import MiniNavbar from "../components/MiniNavbar";


const Contact = () => {
  const { name } = useParams();
  useEffect(() => {
    document.title = "Contact";
  }, [name]);

  return (
    <div className="page-wrapper">

      <ScrollToTop smooth top={300} />
      <MiniNavbar />

      <section className="page-title">
        <div className="auto-container">
          <div className="inner-container contact-container">
            <div className="outer-box">
              <div className="inner clearfix">
                <h1>Contact Us</h1>
                <ul className="bread-crumb clearfix">
                  <li>
                    <a>Home</a>
                  </li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactForm />
      
      <Footer />
    </div>
  );
};

export default Contact;

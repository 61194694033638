import Footer from "../components/Footer";
import MiniNavbar from "../components/MiniNavbar";
import MiniScreen from "../components/MiniScreen";
import { useParams } from "react-router-dom";
import AboutBody from "../components/AboutBody";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";

const About = () => {
  const { name } = useParams();
  useEffect(() => {
    document.title = name;
  }, [name]);

  return (
    <div className="page-wrapper">
      <ScrollToTop smooth top={300} />
      <MiniNavbar />
      <MiniScreen name={name} />
      <AboutBody />
      <Footer />
    </div>
  );
};

export default About;

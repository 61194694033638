
const FaqsQuery = () => {
  
  return (
    <>
      <hr className="mb-0 mt-0" />

      {/* <!-- FAQ Section --> */}
      <section className="faqs-section style-two">
        <div className="auto-container">
          <div className="row">
            {/* <!-- FAQ Column --> */}
            <div className="faq-column col-lg-12 col-md-12 col-sm-12">
              <div class="demo10">
                <div class="container">
                  <h4 class="py-4 text-center text-white">Package & Pricing</h4>
                  <div class="row py-5">
                    <div class="col-md-4 col-sm-6">
                      <div class="pricingTable10">
                        <div class="pricingTable-header">
                          <h3 class="heading">Bronze Plan</h3>
                          <p className="text-white my-1 mb-3">Start From </p>

                          <span class="price-value">
                            <span class="currency">$</span> 300
                            <span class="month">/Truck</span>
                          </span>
                        </div>
                        <div class="pricing-content">
                          <ul>
                            <li>You’re The Boss!</li>
                            <li>No Forced Dispatch</li>
                            <li>Payload & FSC = 100% Yours</li>
                            <li>We Negotiate Top Paying Rates</li>
                            <li>Setup Paperwork Fax/Email Documents</li>
                            <li>Credit Checks</li>
                            <li>Personal Dispatcher</li>
                            <li>Request Quick Pay</li>
                            <li>Request Fuel Advances</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="pricingTable10">
                        <div class="pricingTable-header">
                          <h2 class="heading">Gold Plan</h2>
                          <p className="text-white my-1 mb-3">Start From </p>
                          <span class="price-value mt-1">
                            <span class="currency">$</span> 350
                            <span class="month">/Truck</span>
                          </span>
                        </div>
                        <div class="pricing-content">
                          <ul>
                            <li>Additional Services</li>
                            <li>24/7 Dispatch Support</li>
                            <li>Request Insurance Certificate</li>
                            <li>Driver Director Assistance</li>
                            <li>Negotiate Quick Pay Rates</li>
                            <li>Factor Setup Assistance</li>
                            <li>Collection Assistance</li>
                            <li>Detention Charges Assistance</li>
                            <li>Unused Truck Order Assistance</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="pricingTable10">
                        <div class="pricingTable-header">
                          <h3 class="heading">Custom Plan</h3>
                          {/* <p className="text-white">Start From </p> */}
                         

                          <span class="price-value mb-3">
                          <h4 className="text-white px-2 pt-5">
                            Describe your own custom plan for partial services </h4>
                            {/* <span class="currency">$</span> 30
                            <span class="month">/Truck</span> */}
                          </span>
                        </div>
                        <div class="pricing-content py-5 ">
                          <ul>
                            <li>
                              You may also discuss your custom plan and may ask
                              us for partial services at partial rates
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End FAQ Section --> */}

      <hr className="mb-0 mt-0"></hr>
    </>
  )
}

export default FaqsQuery

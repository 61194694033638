import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ScrollToTop from "react-scroll-to-top"
import Carousel from "react-simply-carousel"
import Footer from "../components/Footer"
import MainNavbar from "../components/MainNavbar"
import MainScreen from "../components/MainScreen"
import "../css/bootstrap.css"
import "../css/owl.css"
import "../css/responsive.css"
import "../css/style.css"
import truckImage from "../images/about-dispatch.jpeg"
import { choise } from "../lists/AboutData"
import { testimonialData } from "../lists/TeamData"
const Home = () => {
  const navigate = useNavigate()
  const { name } = useParams()
  const [activeSlide, setActiveSlide] = useState(0)
  useEffect(() => {
    document.title = "Home"
  }, [name])

  return (
    <div className="page-wrapper">
      <ScrollToTop smooth top={300} />
      <MainNavbar />
      <MainScreen />
      {/* <!-- About Section --> */}
      <section className="about-section ">
        <div className="auto-container ">
          <div className="row ">
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <span className="title">Welcome to </span>
                <h2>BIG AIMS LLC</h2>
                <div className="message text-justify">
                  BIG AIMS LLC is an independent dispatch company located in
                  USA, Sheridan, WY. The prime objective of BIG AIMS LLC is to
                  deliver high-quality logistics services at reduced costs for
                  improved efficiency of business . Our carriers offers range of
                  equipment and features like Dry Vans, Flatbeds, Heavy
                  Equipment and Temperature Controlled transportation. What
                  makes us different from other logistics companies is the
                  relationship with our carriers.
                </div>
                <div className="text text-justify">
                  With years of experience in trucking and freight industry, we
                  have learned how to negotiate the best rates and how to make
                  your freight transportation mechanism easier. Furthermore, our
                  experienced staff provides driver communication, load
                  consolidation, and 24/7 customer service and any other
                  assistance required. .
                </div>
                <blockquote className="blockquote mt-4">
                  <cite>Muhammad Osman, CEO</cite>
                  <p className="text-justify">
                    A chief executive officer (CEO) is the highest-ranking
                    executive of a firm. CEOs act as the company's public face
                    and make major corporate decisions.
                  </p>
                  <span className="icon fa fa-quote-right"></span>
                </blockquote>
                <div className="btn-box">
                  <a onClick={() => navigate("/ABOUT/About")} className="theme-btn btn-style-one">
                    <span className="btn-title">More About</span>
                    <span className="icon fa fa-angle-double-right"></span>
                  </a>
                </div>
              </div>
            </div>

            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="image-box">
                <figure className="image">
                  <img
                    src={truckImage}
                    alt="image Not Found"
                    style={{ height: "550px", objectFit:'cover' }}
                  />
                </figure>
                <a
                  // href="https://www.youtube.com/watch?v=oYN4RStp_t0"
                  className="link lightbox-image"
                  data-fancybox="video"
                >
                  {/* <span className="fa fa-play"></span> */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End About Section --> */}


      <section className="why-choose-techverx bk-Clr">
        <div className="container text-center pb-5 pt-5">
          <h2 className="red-Clr h2-size">
            {/* <Bounce left cascade> */}
            Why Choose BIG AIMS LLC
            {/* </Bounce> */}
          </h2>
          <p className="medium-size-p mb-md-5 pt-4">
            What makes our services better than our contenders is that our
            services are very reasonable regarding cost. We have crafted plans
            for different needs to help you choose the one that suits your
            budget and demands. We eliminate the hustle of paying 10% or more to
            truck dispatch service for every load. This is why our trucking
            dispatch services have become popular globally for all dispatch
            needs.
          </p>
          <div className="row mx-0">
            {choise.map(({ head, para }) => (
              <div className="col-md-6 col-lg-3 ">
                <div className="custom-card">
                  <div className="custom-card-header">
                    <h6 className="custom-card-title font-weight-bold">
                      {head}
                    </h6>
                  </div>
                  <div className="custom-card-body">
                    <p className="ClrText">{para} </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="counter-box counters">
            {count.map(({ number, sign, heading }) => (
              <div className="single-coutnerup">
                <span className="count-num counter text-muted">{number}</span>
                <span className="count-unit text-muted">{sign}</span>
                <p className="counter-title text-white"> {heading}</p>
              </div>
            ))}
          </div> */}
        </div>
      </section>
   

      <hr />
      {/* <!-- Testimonial Section --> */}
      <section className="testimonial-section">
        <div className="layer-image"></div>
        <div className="auto-container clearfix">
          <div className="testimonial-outer">
            <div className="sec-title">
              <span className="title">Testimonial</span>
              <h2>What Our Clients Says</h2>
              <div className="devider">
                <span className="fa fa-cogs"></span>
              </div>
            </div>

            {/* <!-- Testimonial Carousel --> */}
            <div>
              <Carousel
                updateOnItemClick
                containerProps={{
                  style: {
                    width: "100%",
                    justifyContent: "space-between"
                  }
                }}
                activeSlideIndex={activeSlide}
                activeSlideProps={{
                  style: {
                    opacity: "1"
                  }
                }}
                onRequestChange={setActiveSlide}
                forwardBtnProps={{
                  children: ">",
                  style: {
                    width: 30,
                    height: 30,
                    minWidth: 30,
                    color: "red",
                    alignSelf: "center",
                    border: "1px solid black",
                    background: "#111"
                  }
                }}
                backwardBtnProps={{
                  children: "<",
                  style: {
                    width: 30,
                    height: 30,
                    minWidth: 30,
                    color: "red",
                    alignSelf: "center",
                    border: "1px solid black",
                    background: "#111"
                  }
                }}
                itemsToShow={1}
                speed={400}
              >
                {testimonialData.map(({ image, para, title, name }) => (
                  <div
                    style={{
                      width: 600,
                      height: 350,
                      textAlign: "center",
                      opacity: 0.5,
                      lineHeight: "240px",
                      boxSizing: "border-box"
                    }}
                    // key={name}
                  >
                    <div className="testimonial-block">
                      <div className="inner-box">
                        <span className="icon fa fa-quote-left"></span>
                        <div className="text">
                          <span className="text-white text-justify">
                            {para}
                          </span>
                        </div>
                        <div className="info-box">
                          {/* <div className="thumb">
                            <img src={image} alt="Image Not Found" />
                          </div> */}
                          <h6 className="name">{name} </h6>
                          <div className="designation">
                            <span>-</span>
                            {title}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>


      <Footer />
    </div>
  )
}

export default Home

import TeamMember from "../images/team.png"
export const teamMember = [
  {
    img: TeamMember,
    name: "Toy Brooke",
    title: "Dispatcher"
  },

  {
    img: TeamMember,
    name: "Rohan khan",
    title: "Dispatcher"
  },
  {
    img: TeamMember,
    name: "Zack Roger",
    title: "Sale’s Representative"
  },
  {
    img: TeamMember,
    name: "Mike Jr",
    title: "Sale’s Representative"
  }
]
export const testimonialData = [
  {
    para: `Excellent communication, follow thru and very dedicated! Complete ease working with
     BIG AIMS LLC and his team. All are organized and very efficient with their processes.`,
    image: "",
    name: "Mike Berry",
    title: "DDA TRUCKING LLC"
  },
  {
    para: `Amazing company to work with. They were extremely knowledgeable and did an amazing 
    job on our Dispatching. They were very patient 
    with us and were very fair in price. Very very happy with the outcome!`,
    image: "",
    name: "Laalizada",
    title: "LAALI TRANSLATION LLC"
  },
  {
    para: `I worked with BIG AIMS LLC on multiple projects and continue to come back to them.
     They are professional, personable and deliver a great project. They work well with my project feedback,
      often times able to read between the lines of what I am saying, and are very timely with their turnaround 
      times. `,
    image: "",
    name: "Dan ",
    title: "DAN’S OVERNIGHT TRUCKING LLC"
  }
]

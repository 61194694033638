import { useNavigate } from "react-router-dom"
import lightLogo from "../images/light-logo.png"
const Footer = ({price}) => {
  const navigate = useNavigate()
  return (
    <>
      {/* <!-- Contact Info Section --> */}
      <section className="contact-info-section">
        <div className="auto-container pl-3 pr-3">
          <div className="row">
            {/* <!-- Contact Info Block --> */}
            <div className="title-column col-lg-5 col-md-12 col-sm-12">
              <h2>
                <span>Get</span> in Touch
              </h2>
              <div className="text">
                With years of experience in trucking and freight industry, we
                have learned how to negotiate the best rates and how to make
                your freight transportation mechanism easier. Furthermore, our
                experienced staff provides driver communication, load
                consolidation, and 24/7 customer service and any other
                assistance required.
              </div>
            </div>

            {/* <!-- Block Column --> */}
            <div className="block-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column ">
                <div className="row">
                  {/* <!-- Info Block --> */}
                  <div className="contact-info-block col-lg-5 col-md-6 col-sm-12">
                    <div className="inner">
                      <span className="icon flaticon-call-1"></span>
                      <h3>Call us on</h3>
                      <div className="info">
                        <a href="tel:+920398-9898765">+1(804) 591-4526</a>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Info Block --> */}
                  <div className="contact-info-block col-lg-7 col-md-6 col-sm-12">
                    <div className="inner ">
                      <span className="icon flaticon-mails"></span>
                      <h3>Email us</h3>
                      <div className="info">
                        <a href="mailto:xyz@gmail.com">
                          {price ? 'Setup@bigaimsdispatch.com' : 'Accounts@bigaimsdispatch.com'
                          
                        }
                          
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Contact Info Section --> */}
      
      {/* <!--Main Footer--> */}
      <footer className="main-footer">
        {/* <!--footer upper--> */}
        <div className="footer-upper">
          <div className="auto-container pl-3 pr-3">
            <div className="row">
              <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="row clearfix">
                  {/* <!--Footer Column--> */}
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget about-widget">
                      <div className="logo">
                        <a>
                          <img
                            src={lightLogo}
                            alt="LOgo not found"
                            style={{ height: "80px", width: "90px" }}
                          />
                        </a>
                      </div>
                      <div>
                        <p className="text-white mb-2">
                          <span className="redClr font-weight-bold me-1">
                            BIG AIMS LLC
                          </span>
                          <span className="ml-1">
                            is an independent dispatch company located in USA,
                            Sheridan, WY. The prime objective of BIG AIMS LLC is to
                            deliver high-quality logistics services at reduced
                            costs for improved efficiency of business .
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <!--Footer Column--> */}
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    {/* <!--Footer Column--> */}
                    <div className="footer-widget recent-posts ml-3">
                      <h2 className="widget-title">We Also Provide</h2>
                      {/* <!--Footer Column--> */}
                      <div className="widget-content">
                        <ul className="serivce-list clearfix">
                          <li>
                            <a>24/7/365 – Dispatch Assistance</a>
                          </li>
                          <li>
                            <a>Loads for all Equipment Types</a>
                          </li>
                          <li>
                            <a>Request Insurance Certificate</a>
                          </li>
                         
                          <li>
                            <a>Fax/Email Documents</a>
                          </li>
                          <li>
                            <a>No Forced Dispatch</a>
                          </li>
                          <li>
                            <a>Request Quick Pay</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  {/* <!--Footer Column--> */}
                  <div className="footer-column col-xl-5 col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget services-widget">
                      <h2 className="widget-title">Our Services</h2>
                      <div className="widget-content">
                        <ul className="serivce-list clearfix">
                        <li>
                            <a>Credit Checks</a>
                          </li>
                          <li>
                            <a>Trucking Services</a>
                          </li>
                          <li>
                            <a>Specialty Services</a>
                          </li>
                          <li>
                            <a>Complex Logistics</a>
                          </li>
                          <li>
                            <a>Collection Assistance</a>
                          </li>
                          <li>
                            <a>Keep 100% of the Payload</a>
                          </li>
                         
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* <!--Footer Column--> */}
                  <div className="footer-column col-xl-7 col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget newslatter-widget">
                      <h2 className="widget-title">ABOUT COMPANY</h2>
                      <div className="widget-content">
                        <div>
                          <p className="text-white">
                            BIG AIMS LLC is an independent dispatch company
                            located in USA, Sheridan, WY.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h4 className="font-weight-bold chndRed">
                            Opening Hours
                          </h4>
                          <p className="text-white">Monday - Friday</p>
                          <p className="text-white">9:00am to 5:00pm</p>
                          <h4 className="font-weight-bold chndRed mt-2">
                            Address
                          </h4>
                          <p className="text-white">
                            30 N Gould St Ste R, Sheridan, WY, 82801, USA
                          </p>
                        </div>

                        {/* <ul className="social-icon-two mt-2">
                          <li className="title">Follow Us:</li>
                          <li>
                            <a target="_blank">
                              <i className="fa fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a target="_blank">
                              <i className="fa fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a target="_blank">
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a target="_blank">
                              <i className="fa fa-skype"></i>
                            </a>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--Footer Bottom--> */}
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <div className="footer-nav">
                <ul className="clearfix">
                  <li onClick={() => navigate('/')}>
                    <a>Home</a>
                  </li>
                  <li  onClick={() => navigate("/ABOUT/About")}>
                    <a>About Us</a>
                  </li>
                  <li  onClick={() => navigate("/Contact")}>
                    <a>Contact Us</a>
                  </li>
                </ul>
              </div>

              <div className="copyright-text">
                <p className="px-2">
                  2023 © All Rights Reserved by
                  <a>BIG AIMS LLC</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--End Main Footer--> */}
    </>
  )
}

export default Footer

import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import fullWhiteLogo from '../images/fully-white-logo.png'
import bigLogo from '../images/light-logo.png'
const MiniNavbar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const controlNavbar = () => {
    if (window.scrollY > 80) {
      setShow(true)
    } else {
      setShow(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar)
    return () => {
      window.removeEventListener("scroll", controlNavbar)
    }
  }, [])

  const { pathname } = location
  const splitLocation = pathname.split("/")
console.log({splitLocation})
  return (
    <>
      {/* <!-- Main Header--> */}
      <header className="main-header header-style-three">
        {/* <!--Header Lower--> */}
        <div className="header-lower">
          <div className="auto-container">
            <div className="main-box clearfix">
              <div className="pull-left logo-outer">
                <div className="logo">
                  <a>
                    <img
                      src={bigLogo}
                      width="70px"
                      alt="Image not Found"
                    />
                  </a>
                </div>
              </div>

              <div className="nav-outer">
                {/* <!-- Main Menu --> */}
                <nav className="main-menu navbar-expand-md navbar-dark">
                  <div className="navbar-header">
                    {/* <!-- Toggle Button -->       */}
                    <button
                      className="navbar-toggler collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="fa fa-bars"></span>
                    </button>
                  </div>

                  <div
                    className="collapse navbar-collapse clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className={splitLocation[1] === "" ? "activee" : ""}>
                        <a
                          onClick={() => {
                            // setId(2);
                            navigate("/")
                          }}
                        >
                          Home
                        </a>
                      </li>
                      <li onClick={() => navigate("/ABOUT/About")} className={splitLocation[2] === "About" ? "activee" : ""} >
                              <a>About</a>
                            </li>
                      <li
                        className={
                          splitLocation[1] === "Service" ? "activee" : ""
                        }
                      >
                        <a
                          onClick={() => {
                            navigate("/Service")
                          }}
                        >
                          Services
                        </a>
                      </li>
                      <li onClick={() => navigate("/team/Team")}>
                              <a>Team</a>
                            </li>
                      <li
                        className={
                          splitLocation[1] === "Package-pricing" ? "activee" : ""
                        }
                      >
                        <a
                          onClick={() => {
                            navigate("/Package-pricing")
                          }}
                        >
                         Package & Pricing
                        </a>
                      </li> 
                    
                      <li
                        className={
                          splitLocation[1] === "Contact" ? "activee" : ""
                        }
                      >
                        <a
                          onClick={() => {
                            navigate("/Contact")
                          }}
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
                {/* <!-- Main Menu End--> */}

                <div className="outer-box">
                  {/* <!-- Info Box --> */}
                  <div className="info-box">
                    <a href="tel:+923216626216">+1(804) 591-4526</a>
                    <a href="mailto:info@zaichem.com">Accounts@bigaimsdispatch.com</a>
                  </div>

                  {/* <!-- Btn Box --> */}
                  {/* <div className="btn-box">
                    <a className="theme-btn btn-style-one">
                      <span className="btn-title">
                        Build Your <br />
                        Business Profile
                      </span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End Header Lower--> */}

        {/* <!-- Sticky Header --> */}
        <div
          className={`sticky-header ${
            show && "navActive slideInDown animated pull-left"
          }`}
        >
          <div className="auto-container clearfix">
            <div className="logo pull-left">
              <a>
                <img
                  src={fullWhiteLogo}
                  alt="logo not found"
                  width="70px"
                  title=""
                />
              </a>
            </div>

            <div className="pull-right">
              <nav className="main-menu navbar-expand-md navbar-dark">
                <div className="navbar-collapse collapse clearfix setting-nav">
                  <ul className="navigation clearfix new-nav-setting">
                    <li
                      className={splitLocation[1] === "" ? "mini-active" : ""}
                    >
                      <a
                        onClick={() => {
                          // setId(2);
                          navigate("/")
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li onClick={() => navigate("/ABOUT/About")} className={splitLocation[2] === "About" ? "mini-active" : ""}>
                              <a>About</a>
                            </li>

                    <li
                      className={
                        splitLocation[1] === "Service" ? "mini-active" : ""
                      }
                    >
                      <a
                        onClick={() => {
                          navigate("/Service")
                        }}
                      >
                        Services
                      </a>
                    </li>
                    <li onClick={() => navigate("/team/Team")}>
                              <a>Team</a>
                            </li>
                    <li
                        className={
                          splitLocation[1] === "Package-pricing" ? "mini-active" : ""
                        }
                      >
                        <a
                          onClick={() => {
                            navigate("/Package-pricing")
                          }}
                        >
                           Package & Pricing
                        </a>
                      </li> 
                   
                    <li
                      className={
                        splitLocation[1] === "Contact" ? "mini-active" : ""
                      }
                    >
                      <a
                        onClick={() => {
                          navigate("/Contact")
                        }}
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* <!--End Main Header --> */}
    </>
  )
}

export default MiniNavbar

import emailjs, { init } from "@emailjs/browser"
import { useRef } from "react"
import { Fade } from "react-reveal"

const ContactForm = () => {
  const form = useRef()
  init("8r6iUINi3pC_HOCoL")

  const handleSubmit = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        "service_3tk9jb1",
        "template_wy1ytww",
        form.current,
        "N2wvJF7APTApJbsJN"
      )
      .then(
        (result) => {
          alert("Message sent Successfully")
        },
        (error) => {
          alert("Message not sent")
        }
      )
  }

  return (
    <>
      <hr className="mb-0 mt-0" />

      {/* <!-- Faq Form Section --> */}
      <div className="bgImage">
        <section className="faq-form-section">
          <div className="auto-container">
            <div className="inner-container pl-3 pr-3">
              <div className="sec-title light">
                <span className="title">Get IT Now</span>
                <h2>Your Question</h2>
                <div className="devider">
                  <span className="fa fa-cogs"></span>
                </div>
              </div>

              {/* <!-- Faq Form --> */}
              <div className="faq-form ">
                <form onSubmit={handleSubmit} ref={form}>
                  <Fade right cascade>
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          name="username"
                          placeholder="Name"
                          required
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          required
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          required
                        />
                      </div>

                      <div className="form-group col-lg-12 col-md-6">
                        <textarea
                          required
                          name="message"
                          placeholder="Any Questions ?"
                        ></textarea>
                      </div>

                      <div className="form-group col-lg-12 col-md-6">
                        <button
                          className="theme-btn btn-style-two"
                          type="submit"
                          name="submit-form"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Fade>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!--End Contact Section --> */}

      <hr className="mb-0 mt-0" />
    </>
  )
}

export default ContactForm

export const deliverData = [
  {
    classDiv: "bordrrr",
    head: "You’re the Boss",
    para: " We focus on what engages and delights users and combine it with what is technologically feasible and economically viable within a product."
  },
  {
    classDiv: "bordrrr",
    head: "Collection Assistance",
    para: ` We help businesses manage their cloud infrastructures as
        well as architecture, develop and deploy on the AWS cloud by
        leveraging our demonstrated skills and expertise in the
        cloud services domain.`
  },
  {
    classDiv: "bordrrr secondborder",
    head: "Keep 100% of the Payload",
    para: ` We know what it takes to deliver robust, secure,
        high-performance, and enterprise-grade applications and
        products.`
  },
  {
    classDiv: "bordrrr",
    head: " No Forced Dispatch",
    para: `We develop unique and fully customized web and mobile
        applications. We have a creative team capable of adopting
        different styles for various business to ensure digital
        interaction with your organization.`
  },
  {
    classDiv: "bordrrr",
    head: " Request Quick Pay",
    para: ` Through our honed engagement process we are able to add
        value and enrich your ecommerce propositions at every step
        of the project life cycle for both new ecommerce ventures
        and re-platforming projects.`
  },
  {
    classDiv: "bordrrr secondborder",
    head: "Loads for all Equipment Types",
    para: ` Our IT Solutions and Services give you access to our
        proficiency in advanced technologies and proven practices
        along the entire IT life cycle.`
  },
  {
    classDiv: "bordrrr ",
    head: "Fax/Email Documents",
    para: ` Data should help you run your business better. That’s why we
        take a business-led approach to data analytics, to help you
        create sustained business insight and value...`
  },
  {
    classDiv: "bordrrr ",
    head: "Request Fuel Advances",
    para: `  To drive your business forward you need fast and accurate
        reporting, swift implementation of processes and answers to
        simple questions.`
  },
  {
    classDiv: "bordrrr secondborder ",
    head: "24/7/365 – Dispatch Assistance",
    para: `   Information Strategy brings together everything that can
        have an impact on or be impacted by data analytics.`
  },
  {
    classDiv: "bordrrr ",
    head: " Credit Checks",
    para: ` Data should help you run your business better. That’s why we
        take a business-led approach to data analytics, to help you
        create sustained business insight and value...`
  },
  {
    classDiv: "bordrrr ",
    head: " Collection Assistance",
    para: `  To drive your business forward you need fast and accurate
        reporting, swift implementation of processes and answers to
        simple questions.`
  },
  {
    classDiv: "bordrrr  secondborder",
    head: "Dedicated Personal Dispatcher",
    para: `   Information Strategy brings together everything that can
        have an impact on or be impacted by data analytics.`
  },
  {
    classDiv: "bordrrr botmbordr",
    head: " Setup Paperwork",
    para: ` Data should help you run your business better. That’s why we
        take a business-led approach to data analytics, to help you
        create sustained business insight and value...`
  },
  {
    classDiv: "bordrrr botmbordr",
    head: "  Factor Setup Assistance",
    para: `  To drive your business forward you need fast and accurate
        reporting, swift implementation of processes and answers to
        simple questions.`
  },
  {
    classDiv: "bordrrr botmbordr secondborder",
    head: " Request Insurance Certificate",
    para: `   Information Strategy brings together everything that can
        have an impact on or be impacted by data analytics.`
  }
]

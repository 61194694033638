import React from "react";
import { teamMember } from "../lists/TeamData";

import Slide from "react-reveal/Slide";
const TeamBody = () => {
  return (
    <div>
      {/* <!-- Team Section --> */}
      <section className="team-section alternate">
        <div className="auto-container">
          <div className="sec-title text-center">
            <span className="title">Our Team</span>
            <h2>Our Expert Members</h2>
            <div className="devider">
              <span className="fa fa-cogs"></span>
            </div>
          </div>

          {/* <!-- Team Carousel --> */}
          <div className="row">
            {teamMember.map(({ img, name, title }) => (
              <div className="team-block col-lg-3 col-md-6 col-sm-12 ">
                <div className="inner-box">
                  <Slide left cascade>
                    <div className="image-box">
                      <figure className="image">
                        <a>
                          <img src={img} alt="" />
                        </a>
                      </figure>
                    </div>
                  </Slide>
                  <div className="caption-box">
                    <h5 className="name">
                      <a>{name}</a>
                    </h5>
                    <span className="description">{title}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <!-- End Team Section --> */}
    </div>
  );
};

export default TeamBody;

import React from "react";
import { useParams } from "react-router-dom";
import FaqsQuery from "../components/FaqsQuery";
import Footer from "../components/Footer";
import MiniNavbar from "../components/MiniNavbar";
import MiniScreen from "../components/MiniScreen";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";

const FAQs = () => {
  const { name } = useParams();
  useEffect(() => {
    document.title = "Package & pricing";
  }, [name]);
  return (
    <div className="page-wrapper">
      <ScrollToTop smooth top={300} />
      <MiniNavbar />
      <MiniScreen name={name ? name : "Package & pricing"} />
      <FaqsQuery />
      <Footer price/>
    </div>
  );
};

export default FAQs;

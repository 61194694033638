import "../css/flaticon.css"
import "../css/font-awesome.css"
import "../css/slick.css"
import "../css/owl.css"
import "../css/jquery.fancybox.min.css"
import "../css/animate.css"
import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import lightLogo from '../images/light-logo.png'
import whiteLogo from '../images/fully-white-logo.png'
const MainNavbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split("/")

  const [show, setShow] = useState(false)
  const controlNavbar = () => {
    if (window.scrollY > 120) {
      setShow(true)
    } else {
      setShow(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar)
    return () => {
      window.removeEventListener("scroll", controlNavbar)
    }
  }, [])

  return (
    <>
      <div>
        {/* <!-- Main Header--> */}
        <header className="main-header">
          {/* <!--Header Top--> */}
          <div className="header-top">
            <div className="auto-container">
              <div className="inner-container clearfix">
                <div className="top-left">
                  <div className="text upprcase">
                  Be part of the best. Join our team!
                  </div>
                </div>

                {/* <div className="top-right clearfix">
                  <ul className="social-icon-one clearfix text-white">
                    <li>
                      <a target="_blank">
                        <i className="fa fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank">
                        <i className="fa fa-skype"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          {/* <!-- End Header Top --> */}

          {/* <!--Header-Upper--> */}
          <div className="header-upper">
            <div className="auto-container">
              <div className="clearfix">
                <div className="pull-left logo-outer">
                  <div className="logo">
                    <a className="text-light">
                      {/* <h2>

                      BIG AIMS LLC
                      </h2> */}
                      <img src={lightLogo} style={{height:"65px", width: '90px'}}/>
                    </a>
                  </div>
                </div>
                <div className="pull-right upper-right clearfix">
                  {/* <!--Info Box--> */}
                  <div className="upper-column info-box">
                    <div className="icon-box">
                      <span className="flaticon-worldwide"></span>
                    </div>
                    <ul>
                      <li>
                        <strong>MAILING ADDRESS</strong>
                      </li>
                      <li>30 N Gould St Ste R, Sheridan, WY, 82801, USA</li>
                    </ul>
                  </div>

                  {/* <!--Info Box--> */}
                  <div className="upper-column info-box">
                    <div className="icon-box">
                      <span className="flaticon-clock-1"></span>
                    </div>
                    <ul>
                      <li>
                        <strong>Phone No</strong>
                      </li>
                      <li>+1(804) 591-4526</li>
                    </ul>
                  </div>

                  {/* <!--Info Box--> */}
                  {/* <div className="upper-column info-box">
                    <div className="icon-box">
                      <span className="flaticon-mail"></span>
                    </div>
                    <ul>
                      <li>
                        <strong>Email</strong>
                      </li>
                      <li>
                        <a href="mailto:info@zaichem.com ">xyz@gmail.com</a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!--End Header Upper--> */}

          {/* <!--Header Lower--> */}
          <div className="header-lower">
            <div className="auto-container">
              <div className="main-box clearfix">
                <div className="nav-outer ">
                  {/* <!-- Main Menu --> */}

                  <nav className="main-menu navbar-expand-md navbar-light ">
                    <div className="navbar-header">
                      <button
                        className="navbar-toggler collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="#navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="fa fa-bars"></span>
                      </button>
                    </div>

                    <div
                      className="collapse navbar-collapse clearfix setting-nav padin-Zero"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation clearfix">
                        <li
                          className={
                            splitLocation[1] === "" ? "main-active" : ""
                          }
                        >
                          <a>Home</a>
                        </li>
                        <li onClick={() => navigate("/ABOUT/About")}>
                              <a>About</a>
                            </li>
                           
                        {/* <li className="dropdown">
                          <a>About</a>
                          <ul>
                            <li onClick={() => navigate("/ABOUT/About")}>
                              <a>About</a>
                            </li>
                            <li onClick={() => navigate("/team/Team")}>
                              <a>Team</a>
                            </li>
                            <li
                              onClick={() =>
                                navigate("/appreciation/Appreciation")
                              }
                            >
                              <a>Appreciation</a>
                            </li>
                          </ul>
                        </li> */}
                        <li
                          className={
                            splitLocation[1] === "Service" ? "activee" : ""
                          }
                          onClick={() => {
                            navigate("/Service")
                          }}
                        >
                          <a> Services</a>
                        </li>
                        <li onClick={() => navigate("/team/Team")}>
                              <a>Team</a>
                            </li>
                        {/* <li className="dropdown">
                          <a>Courses</a>
                          <ul>
                            <li
                              onClick={() => {
                                navigate("/AllCourse")
                              }}
                            >
                              <a>All Courses</a>
                            </li>
                            <li className=" dropdown">
                              <a>Web Development</a>
                              <ul>
                                <li>
                                  <a>Web Development</a>
                                </li>
                                <li
                                  onClick={() => {
                                    navigate("/course/Frontend Development")
                                  }}
                                >
                                  <a>Frontend Development</a>
                                  <ul>
                                    <li
                                      onClick={() => {
                                        navigate("/course/ANGULAR DEVELOPER")
                                      }}
                                    >
                                      <a>Angular</a>
                                    </li>
                                    <li
                                      onClick={() => {
                                        navigate("/course/React Js")
                                      }}
                                    >
                                      <a>React Js</a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a>Backend Development</a>
                                  <ul>
                                    <li
                                      onClick={() => {
                                        navigate("/course/ASP.NET")
                                      }}
                                    >
                                      <a>Asp.Net Core</a>
                                    </li>
                                    <li
                                      onClick={() => {
                                        navigate("/course/JAVA")
                                      }}
                                    >
                                      <a>JAVA</a>
                                    </li>
                                    <li
                                      onClick={() => {
                                        navigate("/course/PYTHON")
                                      }}
                                    >
                                      <a>Python</a>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                            <li
                              onClick={() => {
                                navigate("/course/JAVASCRIPT")
                              }}
                            >
                              <a>Mobile App</a>
                            </li>
                          </ul>
                        </li> */}
                        {/* <li className="dropdown">
                          <a>portfolio</a>
                          <ul>
                            <li
                              onClick={() => {
                                navigate("/Portfolio")
                              }}
                            >
                              <a>Portfolio</a>
                            </li>
                            <li
                              onClick={() => {
                                navigate("/PortfolioDetails")
                              }}
                            >
                              <a>Portfolio Detail</a>
                            </li>
                          </ul>
                        </li> */}
                        {/* <li
                          onClick={() => {
                            navigate("/FAQs")
                          }}
                        >
                          <a>FAQs</a>
                        </li> */}
                        <li
                        className={
                          splitLocation[1] === "FAQs" ? "mini-active" : ""
                        }
                      >
                        <a
                          onClick={() => {
                            navigate("/Package-pricing")
                          }}
                        >
                         Package & Pricing
                        </a>
                      </li> 
                        <li
                          onClick={() => {
                            navigate("/Contact")
                          }}
                        >
                          <a>Contact</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  {/* <!-- Main Menu End--> */}

                  <div className="outer-box">
                    {/* <!--Search Box--> */}
                    <div className="search-box-outer">
                      <div className="dropdown">
                        <ul
                          className="dropdown-menu pull-right search-panel"
                          aria-labelledby="dropdownMenu3"
                        >
                          <li className="panel-outer">
                            <div className="form-container">
                              <form method="post" action="#">
                                <div className="form-group">
                                  <input
                                    type="search"
                                    name="field-name"
                                    value=""
                                    placeholder="Search Here"
                                    required
                                  />
                                  <button type="submit" className="search-btn">
                                    <span className="fa fa-search"></span>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="btn-box">
                      <a className="theme-btn btn-style-one">
                        <span className="btn-title">
                          Build Your <br />
                          Business Profile
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--End Header Lower--> */}

          {/* <!-- Sticky Header --> */}
          <div
            className={`sticky-header ${
              show && "navActive slideInDown animated pull-left"
            }`}
          >
            <div className="auto-container clearfix">
              <div className="logo pull-left">
                <a className="text-white">
                  {/* <h2>
                    BIG AIMS LLC
                  </h2> */}
                  <img
                    src={whiteLogo}
                    alt=""
                    width="70px"
                    title=""
                  />
                </a>
              </div>

              <div className="pull-right">
                <nav className="main-menu navbar-expand-md navbar-dark">
                  <div className="navbar-collapse collapse clearfix setting-nav">
                    <ul className="navigation clearfix new-nav-setting">
                      <li
                        className={splitLocation[1] === "" ? "mini-active" : ""}
                      >
                        <a
                          onClick={() => {
                            // setId(2);
                            navigate("/")
                          }}
                        >
                          Home
                        </a>
                      </li>
                      <li onClick={() => navigate("/ABOUT/About")}>
                              <a>About</a>
                            </li>

                      <li
                        className={
                          splitLocation[1] === "Service" ? "mini-active" : ""
                        }
                      >
                        <a
                          onClick={() => {
                            navigate("/Service")
                          }}
                        >
                          Services
                        </a>
                      </li>
                      <li onClick={() => navigate("/team/Team")}>
                              <a>Team</a>
                            </li>
                      {/* <li className=" dropdown new-set">
                        <a className="font-weight-bold">Courses</a>
                        <ul>
                          <li
                            className={
                              splitLocation[1] === "AllCourse"
                                ? "mini-active"
                                : ""
                            }
                          >
                            <a
                              onClick={() => {
                                navigate("/AllCourse")
                              }}
                            >
                              All Courses
                            </a>
                          </li>
                          <li className=" dropdown">
                            <a>Web Development</a>
                            <ul>
                              <li>
                                <a>Web Development</a>
                              </li>
                              <li>
                                <a
                                  onClick={() => {
                                    navigate("/course/Frontend Development")
                                  }}
                                >
                                  Frontend Development
                                </a>
                                <ul>
                                  <li>
                                    <a
                                      onClick={() => {
                                        navigate("/course/ANGULAR DEVELOPER")
                                      }}
                                    >
                                      Angular
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() => {
                                        navigate("/course/React Js")
                                      }}
                                    >
                                      React Js
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a>Backend Development</a>
                                <ul>
                                  <li>
                                    <a
                                      onClick={() => {
                                        navigate("/course/ASP.NET")
                                      }}
                                    >
                                      Asp.Net Core
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() => {
                                        navigate("/course/PYTHON")
                                      }}
                                    >
                                      Python
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                navigate("/course/JAVASCRIPT")
                              }}
                            >
                              Mobile App
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown new-set">
                        <a className="font-weight-bold">Portfolio</a>
                        <ul>
                          <li
                            className={
                              splitLocation[1] === "Portfolio"
                                ? "mini-active"
                                : ""
                            }
                          >
                            <a
                              onClick={() => {
                                navigate("/Portfolio")
                              }}
                            >
                              Portfolio
                            </a>
                          </li>
                          <li
                            className={
                              splitLocation[1] === "PortfolioDetails"
                                ? "mini-active"
                                : ""
                            }
                          >
                            <a
                              onClick={() => {
                                navigate("/PortfolioDetails")
                              }}
                            >
                              Portfolio Detail
                            </a>
                          </li>
                        </ul>
                      </li>*/}
                      <li
                        className={
                          splitLocation[1] === "FAQs" ? "mini-active" : ""
                        }
                      >
                        <a
                          onClick={() => {
                            navigate("/Package-pricing")
                          }}
                        >
                          Package & pricing
                        </a>
                      </li> 
                      <li
                        className={
                          splitLocation[1] === "Contact" ? "mini-active" : ""
                        }
                      >
                        <a
                          onClick={() => {
                            navigate("/Contact")
                          }}
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
        {/* <!--End Main Header --> */}
      </div>
    </>
  )
}

export default MainNavbar

import about_truck_1 from "../images/about-truck-1.jpeg"
import about_truck_2 from "../images/about-truck-2.jpeg"
import about_truck_3 from "../images/about-truck-3.jpeg"

export const vision = [
  {
    img: about_truck_1,
    head: `Employees Matter`,
    para: `We know that our company's greatness comes from our employees. In the same way that you support us to be our best, we support you to be your best. From warehouse staff to contracted drivers, we lookout for all of our crew.`
  },
  {
    img: about_truck_2,
    head: `Top Level Benefits`,
    para: `From great health benefits and flexible scheduling, we provide what you need to feel great about going to work. Competitive benefits packages are just the start. Let's talk today about how being a part of our company can take you to the next level.`
  },
  {
    img: about_truck_3,
    head: `High Standards`,
    para: `Sure, we have high standards for our employees, but we have high standards for ourselves, too! We want to be a company that you are proud to be a part of. We pride ourselves on making ethical choices the center of our business plan.`
  }
]

export const choise = [
  {
    head: "Professional",
    para: `We have professional dispatcher with 3 to 5 years of experience that can make you worth hauling in secondsnience.`
  },
  {
    head: "Fast & Secure",
    para: `Procedures of our services are fast and payment methods are flexible for customer convenience.`
  },
  {
    head: "Reliable",
    para: ` We find loads from reliable sources like DAT load Boards ,123 Load Boards and many other reference`
  },
  {
    head: "Flexible Plans",
    para: `We provide flexible means for payment through bank,comcheck, paypal or all type of credit and debit cards`
  }
]

import React from "react";
import Fade from "react-reveal/Fade";
import "../css/style.css";

const MainScreen = () => {
  return (
    <div>
      <div className="img-container main-slider">
        <div className="w-100 d-flex justify-content-start mx-5">
          <Fade left cascade>
            <div className="title-seting text-white ">
              <h3>We Give a Wide Range OF</h3>
              <h1 className="display-4">
                <span className="ITSetting">Dispatch</span> Services For Your <br />
                Corporate Business
              </h1>
              <p className="text-white">
              BIG AIMS LLC DISPATCH SERVICES IS THE BEST COMPANY 
                <br /> FOR YOUR DISPATCH NEEDS
              </p>
             
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default MainScreen;

import { useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { GiTruck } from "react-icons/gi"
import { useLocation, useParams } from "react-router-dom"
import ScrollToTop from "react-scroll-to-top"
import Footer from "../components/Footer"
import MiniNavbar from "../components/MiniNavbar"
import "../css/bootstrap.css"
import "../css/owl.css"
import "../css/responsive.css"
import "../css/style.css"
import { deliverData } from "../lists/serviceData"
const Service = () => {
  const { name } = useParams()
  useEffect(() => {
    document.title = "Services"
  }, [name])
  const location = useLocation()

  return (
    <>
      <div className="page-wrapper">
        <ScrollToTop smooth top={300} />
        <MiniNavbar />
        {/* <!--Page Title--> */}
        <section className="page-title">
          <div className="auto-container">
            <div className="inner-container service-container">
              <div className="outer-box">
                <div className="inner clearfix">
                  <h1>Services</h1>
                  <ul className="bread-crumb clearfix">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li>Services</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--End Page Title--> */}
        <section className="bgdark  pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12 mb-5 pb-5 ">
                <ScrollAnimation animateIn="fadeIn">
                  <h2 className="text-center text-white display-4 mb-2 mt-5">
                    SERVICES
                  </h2>
                </ScrollAnimation>
                <p className="text-white text-center font-weight-bold lead">
                  WE DELIVER QUALITY SERVICES AND USER EXPERIENCE
                </p>
              </div>
              {deliverData.map(({ head, para, classDiv }) => (
                <div
                  className={`col-12 col-sm-12 col-md-6 col-lg-4 ${classDiv}`}
                >
                  <ScrollAnimation animateIn="flipInY" animateOut="flipOutY">
                    <div className=" p-4 hoverEffect">
                      <GiTruck className="text-white" />
                      <h4 className="text-white pb-2">{head}</h4>
                      {/* <p className="textligth">{para}</p> */}
                    </div>
                  </ScrollAnimation>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* <!-- ////////////////////////////Section service End////////////////////////////////////// --> */}
        <section className="news-latters">
          <div className="auto-container">
            <div className="row py-5">
              <div className="col-md-12 col-lg-12 col-sm-12 mb-5 pb-5">
                <h2 className="text-center text-white  mb-2 display-4">
                  Truck Dispatch Services for Professional Owner Operators &
                  Truckers
                </h2>
                <p
                  className="text-light text-center py-4"
                  style={{ fontSize: "24px", lineHeight: "2.5rem" }}
                >
                  Are you an owner operator searching for Truck Dispatch
                  Company? Are you a Trucking Company in need of the best Truck
                  Dispatcher Service in USA? UTD provides Independent Dispatch
                  Services in North America and the United States USA Truck
                  Dispatch handles all back office paperwork, detention
                  collection, and factoring, invoicing, and all other admin
                  tasks. We discover the most active loads required for your
                  freight wants and acquire the best rate available according to
                  the market condition. We propose modified Dispatch Service
                  after a watchful enquiry of our customer’s necessity’s at any
                  given time.At UTD, we offer you the finest possible freight
                  rate for any truckload you may have and be careful of any
                  problems that may arise while driving. We are a self-governing
                  Semi Truck Dispatch Company in North America that supports you
                  and find the best conceivable loads in the market….
                </p>
              </div>

              {/* {smbData.map(({ image, head, para }) => (
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 brder">
                  <div className="card-blog  text-center">
                    <div className=" center-image">
                      <img src={image} className="card-img" alt="Png Pic" />
                    </div>

                    <div className="card-body">
                      <h2 className="text-white">{head}</h2>
                      <p className="text-Clr">{para}</p>
                    </div>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </section>
        {/* <!-- ////////////////////////////right service End////////////////////////////////////// --> */}
        <Footer />
      </div>
    </>
  )
}

export default Service

import about_image from "../images/about-company-2.jpeg"
import price_image from "../images/price-2.jpeg"

export const LanguageData = [
  {
    id: 4,
    name: "Package & pricing",
    titlescreen: "Home",
    Screenimage: price_image
  },
  {
    id: 5,
    name: "About",
    titlescreen: "Home",
    Screenimage: about_image
  },
  {
    id: 6,
    name: "Team",
    titlescreen: "Home",
    Screenimage: "/images/background/team-up.jpg"
  },
  {
    id: 7,
    name: "Appreciation",
    titlescreen: "Home",
    Screenimage: "/images/background/appreciation-messages.jpg  "
  }
]

import { LanguageData } from "../lists/AllData";

const MiniScreen = ({ name }) => {
  const newListOne = LanguageData.filter((v) => v.name === name);

  return (
    <>
      <section class="page-title">
        <div class="auto-container">
          {newListOne.map(({ name, titlescreen, Screenimage }) => (
            <div class="inner-container">
              <img src={Screenimage} alt="LOGO" />
              <div class="outer-box">
                <div class="inner clearfix">
                  <h1>{name} </h1>
                  <ul class="bread-crumb clearfix">
                    <li>
                      <a>{titlescreen} </a>
                    </li>
                    <li>{name} </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default MiniScreen;
